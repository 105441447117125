@tailwind base;
@tailwind components;
@tailwind utilities;

/* font */
@layer base {
  @font-face {
    font-family: "BnnpSansCondBold";
    font-weight: 600;
    src: url("../assets/fonts/bnpp-sans-cond-bold-v2.ttf");
    font-display: block;
  }
  @font-face {
    font-family: "Bnnp-sans-cond-bold";
    font-weight: 600;
    src: url("../assets/fonts/bnpp-sans-cond-bold-v2.ttf");
    font-display: block;
  }
  @font-face {
    font-family: "Bnnp-sans-cond-extrabold";
    font-weight: 900;
    src: url("../assets/fonts/bnpp-sans-cond-extrabold-v2.ttf");
    font-display: block;
  }
  @font-face {
    font-family: "Bnnp-sans-cond-light";
    src: url("../assets/fonts/bnpp-sans-cond-light-v2.ttf");
    font-display: block;
  }
  @font-face {
    font-family: "BNPP Sans Condensed";
    src: url("../assets/fonts/bnpp-sans-cond-v2.ttf");
    font-display: block;
  }
}
body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}
.fix-container-width {
  max-width: 2000px;
  margin: auto;
}
.hero-section {
  background-image: url("../assets/images/Rectangle\ 4.jpg");
  height: 650px;
}
.search-hero-section {
  background-image: url(../assets/images/search_bg.png);
  /* height: 310px; */
}
.about-section-one {
  background-image: url("../assets/images/Rectangle\ 6.png");
}
.about-section-two {
  background-image: url("../assets/images/Rectangle\ 8.png");
}
.about-section-three {
  background-image: url("../assets/images/Rectangle\ 7.png");
}
.about-section-four {
  background-image: url(../assets/images/Rectangle7.png);
}
.portfolio-image-first {
  background-image: url(../assets/images/img-3.png);
}
.portfolio-image-second {
  background-image: url(../assets/images/img-2.png);
}
.portfolio-image-third {
  background-image: url(../assets/images/img-1.png);
}
.portfolio-two-first {
  background-image: url("../assets/images/Rectangle\ 11.png");
}
.portfolio-two-second {
  background-image: url("../assets/images/Rectangle\ 12.png");
}
.portfolio-two-third {
  background-image: url("../assets/images/Rectangle\ 13.png");

  /* background-image: url(https://unsplash.com/photos/m4arB1vofTM); */
}
.portfolio-two-four {
  background-image: url("../assets/images/Rectangle\ 14.png");
}
.image-h-w {
  width: 350px;
  height: 263px;
}
.registration-section-first {
  background-image: url(../assets/images/Rectangle20.png);
  background-size: contain;
}
.khvUfi {
  box-shadow: 0 0 0 0 #333 !important;
  background-color: transparent !important;
}
.eRmJbc {
  box-shadow: 0 0 0 0 #333 !important;
  background-color: transparent !important;
}
.jJKuoL {
  background-color: transparent !important;
  box-shadow: 0 0 1px 3px #00a951;
}

.jJKuoL:hover,
.jJKuoL:focus {
  cursor: pointer;
  box-shadow: 0 0 1px 3px #00a951;
}
.rec-carousel-item-visible {
  padding: 2px !important;
}

.rec.rec-arrow:disabled {
  visibility: hidden;
}
.rec-dot_active {
  box-shadow: 0 0 1px 3px white !important;
}

.rec.rec-arrow:hover {
  box-shadow: 0 0 0 0 #333 !important;
  background-color: transparent !important;
}
.registration-image-md {
  display: block;
}
.registration-image-sm {
  display: none;
}
@media only screen and (max-width: 639px) {
  .registration-section-first {
    background-image: url(../assets/images/Rectangle82.png);
  }
  .register-font-margin-top {
    margin-top: -30px;
  }
  .hero-section {
    background-image: url(../assets/images/Rectangle67.png);
    height: auto;
  }
  .registration-image-md {
    display: none;
  }
  .registration-image-sm {
    display: block;
  }
}
@media only screen and (max-width: 639px) {
  .-mt-54 {
    margin-top: -84px;
  }
  .flex-center-items {
    justify-content: center;
  }
}

@media only screen and (max-width: 1023px) {
  .flex-center-items {
    justify-content: center;
  }
}

@layer utilities {
  .p-15 {
    padding: 3.5rem; /* 60px */
  }
  .pt-15 {
    padding-top: 3.5rem;
  }
  .pb-15 {
    padding-bottom: 3.5rem;
  }
  .pl-15 {
    padding-left: 3.5rem;
  }
  .pr-15 {
    padding-right: 3.5rem;
  }
  .word-spacing-2 {
    word-spacing: -2px;
  }
  .word-spacing-1 {
    word-spacing: 0px;
  }
  .word-spacing-address {
    word-spacing: -5px;
  }
  .word-spacing-text {
    word-spacing: -4px;
  }
  .-mt-54 {
    margin-top: -212px;
  }
}

li > ul {
  transform: translatex(100%) scale(0);
}
li:hover > ul {
  transform: translatex(101%) scale(1);
}
li > button svg {
  transform: rotate(-90deg);
}
li:hover > button svg {
  transform: rotate(-270deg);
}

/* Below styles fake what can be achieved with the tailwind config
   you need to add the group-hover variant to scale and define your custom
   min width style.
   See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
   for implementation with config file
*/
.group:hover .group-hover\:scale-100 {
  transform: scale(1);
}
.group:focus .group-hover\:scale-100 {
  transform: scale(1);
}
.group:hover .group-hover\:-rotate-180 {
  transform: rotate(180deg);
}
.group:focus .group-hover\:-rotate-180 {
  transform: rotate(180deg);
}
.scale-0 {
  transform: scale(0);
}
.min-w-32 {
  min-width: 8rem;
}

.toggle-path {
  transition: background 0.3s ease-in-out;
}
.toggle-circle {
  top: 0.2rem;
  left: 0.25rem;
  transition: all 0.3s ease-in-out;
}
input:checked ~ .toggle-circle {
  transform: translateX(100%);
}
input:checked ~ .toggle-path {
  background-color: #00a951;
}

.filters-dropdown {
  @apply py-4 font-semibold cursor-pointer;
}
.filters-dropdown-white {
  @apply filters-dropdown text-white;
}
.input-cl {
  border-color: #d6d6d6 !important;
}
.input-cl:focus {
  outline: none !important;
  border: 2px solid #00a851 !important;
}
.input-checkbox {
  cursor: pointer;
}
.input-checkbox:focus {
  background-color: #00a951;
}

@media screen and (max-width: 639px) {
  .khvUfi {
    display: none !important;
  }
}
@layer utilities {
  .italic-plc::placeholder {
    font-style: italic;
  }
}
@media screen and (max-width: 1535px) {
  .price-media {
    margin-left: 7.9rem;
  }
}
@media screen and (max-width: 1570px) and (min-width: 320epx) {
  .fix-nav {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .price-media {
    margin-left: 1.7rem;
  }
  .fix-button-size {
    margin-left: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media screen and (min-width: 200px) {
  .fix-img-size {
    width: 90% !important;
  }
}
.menuHover {
  cursor: auto;
}
.menuHover:hover {
  cursor: pointer;
}
.sub-menu {
  left: 70px;
  top: -5px;
}
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
.isExpand {
  transform: rotate(180deg);
}
.custom-min-width {
  min-width: 155px;
}
.close-icon {
  color: #00a851;
}
.flex-basis-35 {
  flex-basis: 35%;
}
.flex-basis-70 {
  flex-basis: 70%;
}
.heartIconContainer {
  background-color: #ffffff;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 20px;
}
.slick-prev {
  left: -2% !important;
  z-index: 1;
}
.slick-next {
  right: -2% !important;
  z-index: 1;
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
}
.z-1 {
  z-index: 1;
}
.custom-slide-btn {
  display: block;
  border: 1px solid black;
  width: 13px;
  height: 13px;
  margin-right: 7px;
  border-radius: 50%;
  background: #ffffff;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.fill {
  background: black;
}
@media screen and (min-width: 1067px) {
  .custom-mt-40 {
    margin-top: -40px;
  }
}
.login-container {
  width: 300px;
}
@media screen and (min-width: 500px) {
  .login-container {
    width: 488px;
    border: 1px solid #c4c4c4;
  }
}
@media screen and (min-width: 768px) {
  .footer-flex {
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .register-wrapper {
    width: 900px;
  }
}
.register-wrapper {
  width: 100%;
}
.border-bottom {
  border-bottom: 1px solid #d6d6d6;
}
.checkbox-wrapper {
  margin-top: 45px;
  margin-bottom: 20px;
}

.all-link-color {
  color: #007265;
}

.map-close {
  top: 8px;
  right: 0.5%;
  position: fixed;
}

.footer-size {
  font-size: 15px;
  width: max-content;
}

.min-full-height {
  min-height: calc(100vh - 375px);
}

.custom-wrap-flex {
  flex-wrap: nowrap;
}

@media screen and (max-width: 1219px) {
  .custom-wrap-flex {
    flex-wrap: wrap;
  }
}

.tooltip {
  background: #ffffff;
}
.tooltip:after {
  content: "";
  position: absolute;
  right: 25px;
  bottom: -6px;
  width: 10px;
  height: 10px;
  background: inherit;
  transform: rotate(45deg);
  border-bottom: 1px solid rgba(156, 163, 175);
  border-right: 1px solid rgba(156, 163, 175);
}

.actual-width {
  width: calc(100% - 1.5rem);
}

.button-line-height {
  line-height: 23px !important;
}

.image-wrapper {
  background-color: #00a851;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  position: relative;
}

.image-wrapper .extend-arrow {
  width: 36px;
  height: 36px;
}
.image-wrapper .extend-arrow .triangle-down {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: 0.15rem;
}

.user-menu-dropdown {
  box-shadow: -1px 5px 15px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: -1px 5px 15px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -1px 5px 15px 0px rgba(0, 0, 0, 0.3);
}

.my_area .my_area_container {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  background-color: #f8f8f8;
}

.my_area .sidebar {
  width: 220px;
  flex: 0 1 auto;
  height: inherit;
}

.my_area .menu {
  width: 170px;
}

.my_area .menu-item {
  min-height: 35px;
  padding: 8px 0;
  margin-bottom: 16px;
}

.my_area .menu-item:hover {
  color: #00a951;
  cursor: pointer;
}

.my_area .menu-item-active {
  color: #00a951;
  background: linear-gradient(270deg, rgba(3, 124, 75, 0.1) 0%, rgba(3, 124, 75, 0) 100%);
  border-right: 4px solid #00a951;
}

.my_area .table-header {
  background-color: #f8f8f8;
}

.my_area .documents-link {
  color: #0679ff;
  text-decoration: none;
  font-weight: 600;
}

.message h3 {
  font-size: 20px;
  line-height: 14px;
  color: #011126;
  font-weight: 500;
  /* flex: 0 1 40%; */
  padding-right: 4px;
}

.message span {
  /* flex: 0 1 50%; */
  padding-top: 2px;
  font-size: 14px;
  color: rgba(1, 17, 38, 0.4);
  font-weight: 300;
}

.message p span {
  color: #011126;
  font-size: 16px;
  font-weight: 300;
  text-align: justify;
}

.message .download-file,
.message .confirmed {
  /* color: #0679ff; */
  color: #00a851;
}

.message .download-file:hover {
  text-decoration: underline;
}

.message .file-info {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 300;
  line-height: 14px;
}

.write-message-wrapper {
  max-height: 160px;
  overflow-y: auto;
}

.write-message {
  outline: none;
  padding: 6px;
  overflow: hidden;
  position: relative;
  word-wrap: break-word;
  min-height: 32px;
}

.write-message:empty::before {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(placeholder);
  display: block;
  color: #bbb;
  padding: 6px;
  font-style: italic;
  max-height: 160px;
  min-height: 32px;
}

.write-message::placeholder {
  color: #bbb;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  font-size: 14px;
}

#attachment-upload {
  width: inherit;
  max-width: 100%;
  text-align: center;
}

.attachment {
  border-radius: 50%;
  background-color: #00a851;
  width: 34px;
  height: 34px;
}

.send {
  width: 30px;
  height: 26px;
}

.messages {
  max-height: 350px;
  overflow: auto;
}

@media screen and (min-width: 756px) {
  .write-message::placeholder {
    font-size: 16px;
  }

  #attachment-upload {
    width: 400px;
  }

  .attachment {
    width: 44px;
    height: 44px;
  }

  .send {
    width: 35px;
    height: 30px;
  }

  .messages {
    max-height: 500px;
  }
}

.user-message {
  background-color: #00a851;
  padding: 8px 10px !important;
  border-radius: 6px 6px 0 6px;
  color: #fff !important;
  display: inline-block;
  box-shadow: 0px 6px 10px -7px #000;
}

.bnl-message {
  background-color: #efefef;
  padding: 8px 10px !important;
  border-radius: 6px 6px 6px 0;
  display: inline-block;
  box-shadow: 0px 3px 10px -8px;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
  color: #00a851;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.my_request_tbody::before {
  height: 15px;
  content: "";
  display: block;
}

.status_message {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

input[type="checkbox"]:disabled {
  background: #f2f2f2;
  border-color: #dcdcdc;
  cursor: not-allowed;
}

.truncate-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.price-width-place {
  text-align: right;
  min-width: 155px;
}

.assets-icons li:last-child {
  margin-bottom: 0 !important;
}

.bid-input::-webkit-inner-spin-button,
.bid-input::-webkit-outer-spin-button {
  opacity: 1;
  -webkit-appearance: inner-spin-button !important;
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.bid-input-container {
  position: relative;
}

/* Styles for the placeholder when it's not focused */
.bid-input-container label {
  position: absolute;
  top: -10px;
  background: white;
  padding: 0 2px;
  left: 10px;
  font-size: 14px;
  color: #ccc;
  pointer-events: none; /* Ensure the label doesn't interfere with input */
  transition:
    top 0.2s,
    font-size 0.2s,
    color 0.2s; /* Transition effects */
}

.bid-modal-container {
  right: 0;
  top: 56px;
}

.async-search-control {
  border-color: #ccc !important;
  box-shadow: 0 0 0 0px #ccc !important;
}

.async-search-control-input > input {
  outline: none !important;
  box-shadow: none !important;
}
@media (max-width: 640px) {
  .async-search-control-multivalue-max-w {
    max-width: 138px;
  }
}
.async-search-control-multiValueRemove {
  border-radius: 50%;
  color: green;
  background: white;
  align-self: center;
  margin: 0 2px;
}

.async-search-control-multiValueRemove:hover {
  background-color: transparent !important;
  color: white !important;
}

.bottom-box-shadow {
  -webkit-box-shadow: 0 4px 10px -11px #313131;
  -moz-box-shadow: 0 4px 10px -11px #313131;
  box-shadow: 0 4px 10px -11px #313131;
}

.property-card-price-width {
  min-width: 140px;
}

/* Styles for Card selection on Google Maps */
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-d {
  max-height: none !important;
}

/* Kaaja Iframe */
.kaaja-iframe {
  scrollbar-width: thin;
  height: 100%;
}

.react-international-phone-country-selector-button {
  padding: 0 4px !important;
  height: 3rem !important;
}
